<template>
  <div class="pa-10">
    <companies />
  </div>
</template>

<script>
import companies from "@/components/companies.vue";
export default {
  components: {
    companies,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
